<template>
  <v-container>
    <div v-if="loaded">
      <v-row>
        <v-col cols="8" style="font-size: 18px!important; font-family: Blinker-Bold!important; color: #48b284!important;
                              height: 100%; display: flex!important; align-self: center; text-align: left">
          {{ post.title }}
        </v-col>
        <v-col cols="4">
          <img :src="baseURL + post.image" width="100%" style="border-radius: 15px">
        </v-col>
      </v-row>
      <div v-html="post.text" style="text-align: justify; margin-top: 15px"></div>
    </div>
  </v-container>
</template>

<script>
export default {
  name: "Post",
  data() {
    return {
      post: null,
      loaded: false
    }
  },
  methods: {
    getData() {
      window.axios
          .get('api/blog/' + this.$route.params.id)
          .then((res) => {
            this.post = res.data.data
            this.loaded = true
          })
    }
  },
  mounted() {
    this.getData()
  },
  watch: {
    $route() {
      this.getData()
    }
  }
}
</script>

<style scoped>

</style>